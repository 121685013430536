import React from 'react';
import { graphql } from 'gatsby'
import { css } from '@emotion/react';
import { setName } from '../extra/util';
import SEO from '../components/seo';
import BlockContent from '../components/block-content';

export default function Page({ data }) {
  setName(data.page.title);

  return (
    <>
      <div
        className="type--50"
        css={css`
          background-color: ${data.page.color?.hex};
          padding: var(--margin);

          h4 {
            font-family: 'Marist', serif;
            text-align: center;
          }

          a {
            color: var(--black);

            &:hover {
              color: var(--white);
            }
          }
        `}
      >
        <BlockContent content={data.page._rawContent} />
      </div>
    </>
  );
}

export function Head({ data }) {
  return <SEO title={`West Space – ${data.page?.title}`} />
}

export const query = graphql`
  query PageTemplateQuery($slug: String!) {
    page: sanityPage(slug: { current: { eq: $slug } }) {
      title
      color {
        hex
      }
      _rawContent(resolveReferences: { maxDepth: 10 })
    }
  }
`;
